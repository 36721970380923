import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { App, Darkmode, Test2, Test3, About, Coding, Portfolio } from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/test" element={<Darkmode />}></Route>
      <Route path="/test2" element={<Test2 />}></Route>
      <Route path="/test3" element={<Test3 />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/coding" element={<Coding />}></Route>
      <Route path="/portfolio" element={<Portfolio />}></Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
