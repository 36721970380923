import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function HomeButton(props) {
    return (
        <a href="/about" className="animate-fadeInDelay2 group relative flex justify-center items-center rounded-[25px] transition duration-200 ease-in-out hover:scale-105 hover:bg-blue-500 bg-gray-200 dark:bg-gray-400 dark:hover:bg-gray-300 h-40 lg:w-64 xl:w-60 w-52 drop-shadow-lg">
            <div>
                <div className="flex items-center transition duration-300 mt-5 group-hover:text-white dark:group-hover:text-blue-500 group-hover:scale-60 group-hover:-translate-y-3 text-blue-500 dark:text-white drop-shadow-lg">
                <FontAwesomeIcon icon={props.icon} className="h-20" />
                </div>
                <div className="flex justify-center items-center opacity-0 transition -z-10 ease-in-out duration-200 group-hover:opacity-100 text-white dark:text-blue-500 text-xl">
                {props.subtitle}
                </div>
            </div>
        </a>
    )
}