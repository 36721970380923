import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faArrowsRotate, faBookOpen, faCode, faFaceSmile, faHouse, faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Outlet } from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useState } from "react";
import logo_blk from "./Jold_Logo_2.png";
import logo_wht from "./jold_wht.png";
import HomeButton from "./components/HomeButton";
import 'animate.css';

library.add(fab, faWrench, faBookOpen, faFaceSmile, faCode, faHouse, faArrowsRotate);

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h2 className="text-gray-600 text-lg">Welcome to Jold.io</h2>
        <p>
          Website is currently in development, come back soon{" "}
          <Link to="/test3">:)</Link>
        </p>
      </header>
      <Outlet />
    </div>
  );
}

export function Darkmode() {
  const [isDarkMode, setDarkMode] = useState(false);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
    if (isDarkMode) {
      document.body.classList.remove("dark");
    } else {
      document.body.classList.add("dark");
    }
};

  return (
    <div className="dark:bg-gray-600">
      <div className="container mx-auto">
        <div id="content">
          <div className="flex md:sticky top-0">
            <ul className="flex w-full justify-between align-baseline mt-5 mx-5">
              <li>
                <FontAwesomeIcon icon="house" className="h-[50px] w-[50px] dark:text-gray-300 text-blue-500"/>
              </li>
              <li>
                <DarkModeSwitch
                  style={{ marginBottom: '2rem' }}
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  size={50}
                  moonColor={'#E5E7EB'}
                  sunColor={'#3482F6'}
                />
              </li>
            </ul>
          </div>

          <body className="App-body dark:bg-gray-600">
            <div className="content-center h-full">
              <div className="flex justify-center items-center -z-10 mt-10 mb-20 animate-fadeIn">
                <img
                  src={logo_blk}
                  alt="Jold Logo"
                  className="h-64 flex justify-center items-center"
                ></img>
              </div>

              <div className="flex justify-center animate-fadeInDelay1">
                <div className="bg-gray-300 w-5/6 rounded-[25px] mb-10 drop-shadow-lg">
                  <div className="container">
                    <div className="text-center mt-10 mx-10 ">
                      <p className="mb-5 text-2xl dark:white">Welcome to Jold.io - I'm Jack!</p>
                      <p className="mb-5 text-xl">I'm 25 years old, and have been working in Software & Web Development for just under 2 years</p>
                      <p className="mb-5 text-xl">Here, you can find out a little bit more about me, as well as my portfolio of work</p>
                      <p className="mb-5 text-xl">Feel free to take a look around and have fun!</p>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs:grid-cols-1 gap-x-64 gap-y-12 content-end justify-items-center py-10 md:px-40 sm:px-10">
                      <HomeButton icon="face-smile" subtitle="About Me"/>
                      <HomeButton icon="code" subtitle="Coding"/>
                      <HomeButton icon="book-open" subtitle="Portfolio"/>
                      <HomeButton icon="wrench" subtitle="Tools"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </body>

        </div>
      </div>
    </div>

   
    
  );
}

export function Test2() {
  const [isDarkMode, setDarkMode] = useState(false);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  };

  function randomNum(num1) {
    var num2 = Math.floor(Math.random() * (5 - 1 + 1)) + 1

    if (num1 !== num2) {
      setNum(num2)
    } else {
      randomNum(num2)
    }
  }

  const [num, setNum] = useState(4);

  var backgroundScreenColour = 'h-screen'
  var textboxColour = 'flex justify-center w-[60%] rounded-lg drop-shadow-lg'
  var joldTextColour = 'mx-2 text-2xl dark:white transition group-hover:scale-125 font-bold group-hover:font-extrabold ease-in-out origin-bottom'
  var changeColourButton = 'h-[32px]'

  const handleChangeColour = () => {
    randomNum(num)

    changeColourButton += ' transition duration-700 rotate-180'
  }

  var sunColour = ''
  var moonColour = ''

  if (num === 1) {
    // Red
    backgroundScreenColour += ' bg-red-100 dark:bg-red-800'
    sunColour += 'rgb(254 202 202)'
    moonColour += 'rgb(153 27 27)'
    textboxColour += ' bg-red-500'
    joldTextColour += ' text-red-200'
    changeColourButton += ' dark:text-red-100 text-red-800'
  } else if (num === 2) {
    // Orange
    backgroundScreenColour += ' bg-orange-100 dark:bg-orange-800'
    moonColour += 'rgb(154 52 18)'
    sunColour += 'rgb(254 215 170)'
    textboxColour += ' bg-orange-500'
    joldTextColour += ' text-orange-200'
    changeColourButton += ' dark:text-orange-100 text-orange-800'
  } else if (num === 3) {
    // Green
    backgroundScreenColour += ' bg-green-100 dark:bg-green-900'
    moonColour += 'rgb(22 101 52)'
    sunColour += 'rgb(187 247 208)'
    textboxColour += ' bg-green-500'
    joldTextColour += ' text-green-200'
    changeColourButton += ' dark:text-green-100 text-green-900'
  } else if (num === 4) {
    // Cyan
    backgroundScreenColour += ' bg-cyan-100 dark:bg-cyan-700'
    moonColour += 'rgb(14 116 144)'
    sunColour += 'rgb(165 243 252)'
    textboxColour += ' bg-cyan-500'
    joldTextColour += ' text-cyan-200'
    changeColourButton += ' dark:text-cyan-100 text-cyan-700'
  } else if (num === 5) {
    // Purple
    backgroundScreenColour += ' bg-purple-100 dark:bg-purple-700'
    moonColour += 'rgb(126 34 206)'
    sunColour += 'rgb(233 213 255)'
    textboxColour += ' bg-purple-500'
    joldTextColour += ' text-purple-200'
    changeColourButton += ' dark:text-purple-100 text-purple-700'
  }



  return (
    <div className={backgroundScreenColour}>
      <div className="flex sticky top-0">
        <ul className="flex w-full justify-between align-baseline mt-5 mx-5 animate__animated animate__fadeIn animate__slower animate__delay-1s">
          <li>
            <FontAwesomeIcon id="refreshColourButton" icon="arrows-rotate" className={changeColourButton} onClick={handleChangeColour}/>
          </li>
          <li>
            <DarkModeSwitch
            style={{filter: 'drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))'}}
              checked={isDarkMode}
              onChange={toggleDarkMode}
              size={40}
              moonColor={moonColour}
              sunColor={sunColour}
            />
          </li>
        </ul>
      </div>
      <div className="content-center animate__animated animate__headShake animate__delay-5s animate__repeat-2" id="logo-parent">
        <div className="flex justify-center items-center mb-10 animate__animated animate__fadeIn animate__slower">
          
          <Link to="/about">
          <img
            src={logo_blk}
            alt="Jold Logo"
            className="flex justify-center items-center h-96"
          ></img>
          </Link>
        </div>
      </div>

      <div className="flex justify-center animate__animated animate__fadeIn animate__slower animate__delay-1s">
        <div className={textboxColour}>
          <div className="text-center m-10 ">
            <div className="flex group">
              <div className="text-2xl dark:white transition group-hover:translate-x-[-8px] ease-in-out">Welcome to </div>
              <div className={joldTextColour}>Jold.io</div>
              <div className="text-2xl dark:white transition group-hover:translate-x-[8px] ease-in-out"> - I'm Jack!</div>
            </div>
          <p className="mt-5 text-xl">Click the logo to see more! 😊</p>
        </div>
      </div>
    </div>
  </div>

  )
}

export function Test3() {
  const initialDarkModeState = document.body.classList.contains('dark')
  
  const [isDarkMode, setDarkMode] = useState(!initialDarkModeState)
  const [imgRef, setImgRef] = useState(logo_wht)

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);

    document.getElementById('jold-logo').classList.toggle('rotate-180')
    document.body.classList.toggle("dark");
    if (isDarkMode) {
      setImgRef(logo_wht)
    } else {
      setImgRef(logo_blk)
    }
  };

  var backgroundScreenColour = 'h-screen dark:from-[#094565] dark:to-[#5E8CD0] bg-gradient-to-b from-[#5ACCF1] to-[#DBE9EE]'
  var textboxColour = 'flex justify-center w-[60%] rounded-lg drop-shadow-lg dark:text-white text-black font-montserrat'
  var joldTextColour = 'mx-3 text-4xl dark:white transition group-hover:scale-125 font-bold group-hover:font-extrabold ease-in-out origin-bottom dark:text-[#DBE9EE] text-[#094565]'

  // var backgroundScreenColour = 'h-screen dark:from-cyan-700 dark:to-sky-900 bg-gradient-to-b from-cyan-100 to-sky-300'

  return (
    <div className={backgroundScreenColour}>
      <div className="flex sticky top-0">
        <ul className="flex w-full justify-between align-baseline mt-5 mx-5 animate__animated animate__fadeIn animate__slower animate__delay-1s">
          <li>
          </li>
          <li>
            <DarkModeSwitch
              style={{filter: 'drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))'}}
              checked={isDarkMode}
              onChange={toggleDarkMode}
              size={40}
              moonColor={'#094565'}
              sunColor={'#5ACCF1'}
            />
          </li>
        </ul>
      </div>
      <div className="content-center animate__animated animate__headShake animate__delay-5s animate__repeat-2" id="logo-parent">
        <div className="flex justify-center items-center mb-10 animate__animated animate__fadeIn animate__slower">
          <Link to="/about">
            <img id="jold-logo" src={imgRef} alt="Jold Logo" className="flex justify-center items-center h-96 transition duration-1000"></img>
          </Link>
        </div>
      </div>

      <div className="flex justify-center animate__animated animate__fadeIn animate__slower animate__delay-1s">
        <div className={textboxColour}>
          <div className="text-center m-10">
            <div className="flex group">
              <div className="text-4xl dark:white transition group-hover:translate-x-[-14px] ease-in-out">Welcome to </div>
              <div className={joldTextColour}>Jold.io</div>
              <div className="text-4xl dark:white transition group-hover:translate-x-[16px] ease-in-out"> - I’m Jack!</div>
            </div>
          <p className="mt-5 text-2xl">Click the logo to learn more about me</p>
        </div>
      </div>
    </div>
  </div>

  )
}

export function About() {
  return (
    <div>
      <div className="dark:text-red-500">
        Test 123
      </div>
    </div>
  )
}

export function Coding() {

}

export function Portfolio() {

}

export function Tools() {

}

export function App() {
  return <Home />;
}
